var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-full grid grid-cols-12 gap-x-6 relative" },
    [
      _c("p", { staticClass: "label", class: _vm.labelAddClass }, [
        _vm.alert
          ? _c("span", [
              _c("img", {
                staticClass: "w-4 h-4 inline-block",
                attrs: { src: require("@/assets/image/alert_icon.svg") },
              }),
            ])
          : _vm._e(),
        _vm._v(" " + _vm._s(_vm.label) + " "),
      ]),
      _vm.openInputField
        ? [_vm._t("default")]
        : [
            _vm.gridType !== "label-1"
              ? _c("FlTruncatableSpan", {
                  staticClass: "field truncate text-justify break-words",
                  class: _vm.fieldAddClass,
                  attrs: { value: _vm.displayValue },
                })
              : _c(
                  "p",
                  {
                    staticClass: "field text-justify break-words",
                    class: _vm.fieldAddClass,
                  },
                  [_vm._v(_vm._s(_vm.displayValue))]
                ),
            _vm.flErrors
              ? _c(
                  "p",
                  {
                    staticClass: "text-xs text-left text-red-600",
                    class: _vm.errorAddClass,
                  },
                  [_vm._v(_vm._s(_vm.flErrors))]
                )
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }